* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Fira Sans", sans-serif;
}

body {
    overflow: hidden;
}

.App {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.camera {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.control {
    position: fixed;
    display: flex;
    z-index: 10;
    bottom: 0;
    width: 100%;
    padding-bottom: 30px;
    justify-content: center;
    /* border: 1px solid red; */
}

.scanButton {
    width: 70px;
    height: 70px;
    margin: auto auto auto calc(50% - 35px);
}

.uploadButton {
    width: 50px;
    height: 50px;
    margin: auto;
}

.uploadLabel {
    margin-right: 30px;
    display: flex;
}

canvas {
    width: 100%;
    height: auto;
}

.loading {
    position: fixed;
    display: none;
    z-index: 20;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
}

.loading.display {
    display: flex;
}

.leaflet-container {
    height: 100%;
}

.transition {
    z-index: 2;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 38px;
    height: 38px;
    z-index: 500;
}

.close:hover {
    opacity: 0.8;
    cursor: pointer;
}

.map {
    width: 100%;
    height: 100%;
}
